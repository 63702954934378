
.slick-slider {
	margin-bottom: 40px;
}

.slick-slide {
	width: 100%;
}

.slick-slider .slick-slide .slide {
	transform: scale(85%);
	margin: 0 auto;
	padding-bottom: 30px;
	transition: transform .5s ease-in-out;
}
.slick-current + .slick-slide .slide {
	transform: scale(100%);
	z-index: 999;
	margin-bottom: 0;
}
