@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$premium: #36D5E8;
$dark: #2A3547;
$white: #ffffff;
$medium: #424242;
$lite: #98999C;
$blue: #2F8BA8;
$blue-lite: #DAEAFE;
$lite-blue: #D3EDFB;
$bg-lite: #F7F8FC;
$intro: #5783C4;
$shadow-10: 0 2px 10px rgba(0,0,0,.02);

$fira: 'Fira Sans', sans-serif;
$play: 'Playfair Display', sans-serif;

$basic: $fira;
$serif: $play;

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }
