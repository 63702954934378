.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 40px;
  margin-left: 40px;
  padding: 0 24px;
  font-size: .9em;
  font-weight: 500;
  letter-spacing: .05em;
  text-transform: uppercase;
  border-radius: 4px;
  transition: all ease-out .3s;
  @include v-sm {
    zoom: .85; }
  &:hover {
    background-color: $dark; }
  &--premium {
    color: $white;
    background-color: $premium; }
  &--big {
    padding: 0 48px;
    height: 60px;
    font-size: 1em; } }

