#cta_block {
  background: $white url(../../assets/img/cta-bg.jpg) center center no-repeat;
  background-size: cover;
  @include v-sm {
    background-size: 120%; }
  @include v-xs {
    background-image: none;
    background-color: $white; } }

.cta {
  width: 60%;
  margin: 0 auto;
  @include v-sm {
    width: 72%;
    height: 280px; }
  @include v-xs {
    width: 88%;
    margin: 0 auto;
    height: auto; }
  &__text {
    font-size: 1.25em;
    line-height: 1.75;
    @include v-sm {
      font-size: 1.025em;
      line-height: 1.5;
      margin-top: -10%; } }
  &__link {
    margin: 60px auto 80px;
    @include v-sm {
      margin: 10px auto 40px; } } }
