#mission_block {
	width: 100%;
	position: relative;
	margin: -100px auto 0;
	padding-bottom: 120px;
	background-color: $dark;
	z-index: 999;
	@include v-md {
		margin-top: -60px;
		padding-bottom: 60px; }
	@include v-xs {
		margin-top: -360px;
		padding-bottom: 70px; }
	.mission {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: space-between;
		line-height: 1.5;
		margin: 0 auto;
		@include v-md {
			padding-left: 10%;
			padding-right: 10%; }
		&__title {
			margin: -4px 0;
			padding: 0;
			color: $premium;
			font-size: 1.35em;
			font-weight: 400; }
		&__text {
			color: $white;
			line-height: 1.75;
			font-weight: 400; }
		&__block {
			margin-top: 60px;
			&--first {
				width: 50%;
				margin: 0 5%; }
			&--second {
				width: 25%;
				margin: 0 5%; }
			@include v-sm {
				display: block;
				margin-left: auto;
				margin-right: auto;
				padding-top: 25px;
				width: 100%;
				text-align: center;
				clear: both; } } }
	&.home {
		margin: -60px auto 0;
		@include v-md {
			margin-top: 0; }
		@include v-xs {
			margin-top: 0; } }
	&.about {
		margin: 0 auto;
		background-color: $bg-lite;
		.mission {
			&__text {
				color: $dark;
				line-height: 1.75;
				font-weight: 400; }
			&__title {
				font-weight: 500; } } } }
