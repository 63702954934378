#reviews_block {
  padding-bottom: 80px;
  background-color: $white;
  @include v-xs {
    padding-bottom: 0; }
  &.reviews {
    background-color: transparent; } }

.review {
  blockquote {
    padding: 0; }
  &__link {
    margin: 60px auto;
    text-align: center;
    @include v-sm {
      margin: 0 auto; }
    @include v-xs {
      .btn {
        width: 80%;
        margin: 0 auto -7%; } } }
  &__block {
    display: block;
    width: 50%;
    padding: 5%;
    @include v-md {
      padding: 4% 2%; }
    @include v-sm {
      width: 100%;
      padding: 0; } }
  &__right,
  &__left {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
  &__right {
    .review {
      &__block {
        order: 2; }
      &__image {
        order: 1; } } }
  &__left {
    order: 1;
    .review {
      &__block {
        order: 1;
        @include v-sm {
          order: 2; } }
      &__image {
        order: 2;
        @include v-sm {
          order: 1; } } } }
  &__image {
    display: block;
    height: 420px;
    width: 40%;
    margin: 5%;
    border-radius: 8px !important;
    object-fit: cover;
    @include v-sm {
      width: 100%;
      height: 300px;
      margin: -2% 5% -2%; }
    @include v-xs {
      display: none; }
    &--xs {
      display: none;
      @include v-xs {
        width: 80%;
        margin: -2% auto;
        display: block; } } }

  &__name {
    padding: 10% 7.5% 0;
    color: $premium;
    font-size: 2em;
    font-weight: 500;
    @include v-sm {
      padding: 5% 7.5% 0; }
    @include v-xs {
      padding: 10% 12% 0;
      font-size: 1.75em; } }
  &__position {
    display: block;
    margin: -8px 0 40px;
    color: $lite;
    font-size: 1.1em;
    font-family: $play;
    font-style: italic; }
  &__text {
    margin: 24px auto 40px;
    color: $medium;
    font-size: 1.25em;
    font-family: $play;
    font-style: italic;
    line-height: 2;
    @include v-sm {
      margin: -15px auto 40px; }
    @include v-xs {
      font-size: 1.05em; } } }
