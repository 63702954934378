.footer {
  position: relative;
  background-color: darken($dark, 5%);
  padding-bottom: 24px;
  z-index: 1;
  &[current="about"] [data-active="about"],
  &[current="product"] [data-active="product"],
  &[current="reviews"] [data-active="reviews"],
  &[current="privacy"] [data-active="privacy"] {
    color: $premium;
    cursor: default; }
  @include v-sm {
    padding-top: 25px; }
  @include v-xs {
    margin-top: -10px;
    padding-top: 60px; }
  .partners {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 48px;
    height: 160px;
    @include v-sm {
      height: 100px;
      width: 80%;
      margin: 0 auto; }
    &__link {
      margin: 0 16px;
      img {
        width: 40px;
        transition: all ease-out .3s;
        @include v-sm {
          width: 32px; } } } } }
.menu {
  &--footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    &__page {
      justify-content: center; }
    @include v-sm {
      flex-flow: column nowrap;
      margin: 40px auto 32px; }
    &__text,
    &__link {
      margin: 0 20px 16px;
      color: $lite-blue;
      font-size: 1em;
      transition: all ease-out .3s;
      @include v-xs {
        margin: 25px auto -10px;
        display: inline-block;
        width: 100%; }
      &:hover,
      &.active {
        color: $premium; }
      &.active {
        &:hover {
          cursor: default; } } }
    &__text {
      margin-top: 2px;
      padding-top: 12px;
      font-size: 0.975em;
      font-weight: 400;
      @include v-xs {
        margin-bottom: 25px;
        font-size: 0.85em; }
      &:hover {
        color: $lite-blue; }
      small {
        opacity: .85;
        font-size: .85em;
        font-weight: 400;
        font-style: italic;
        @include v-md {
          display: inline-block;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          line-height: 1.75; }
        @include v-xs {
          margin-top: -25px;
          margin-bottom: -15px;
          width: 80%;
          font-size: 0.75em;
          line-height: 1.5; } } } } }
