.section-title {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 72px 0 128px;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  @include v-sm {
    font-size: 1.4em;
    padding: 40px 0 80px; }
  &:before {
    position: absolute;
    bottom: 64px;
    left: calc(50% - 40px);
    right: calc(50% - 40px);
    display: block;
    content: '';
    width: 80px;
    height: 1px;
    background-color: $premium; }
  &__default {
    color: $dark; }
  &__blue {
    margin-top: 24px;
    color: $premium;
    font-weight: 400;
    @include v-sm {
      margin-top: 0;
      font-size: .95em; } }
  &__invert {
    margin-top: 25px;
    color: $white; } }

.section-title__invert {
  &:before {
    position: absolute;
    bottom: 64px;
    left: calc(50% - 40px);
    right: calc(50% - 40px);
    display: block;
    content: '';
    width: 80px;
    height: 1px;
    background-color: $blue-lite; } }
