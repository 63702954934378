.page {
  width: 100%;
  height: auto;
  padding: 80px 0 2vh;
  &__privacy {
    padding-top: 0; }
  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: auto;
    min-height: 100vh; }
  .section-title {
    padding: 72px 0 80px;
    &:before {
      bottom: 40px; } }
  .container {
    padding: 0;
    margin: 0 auto; }
  &__navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    height: 100px; }
  &__content {
    width: 100%;
    padding: 1vh 10vw 15vh; }
  &__header {
    width: 100%;
    padding: 0 60px;
    background: $lite url(../../assets/img/intro-001.jpg) center center no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    @include v-xs {
      padding: 0 25px; } }
  &__subtitle {
    font-size: 1.25em;
    padding: 30px 0 15px;
    font-weight: 500; }
  &__heading {
    color: $white;
    font-size: 1.25em;
    font-weight: 400;
    text-transform: uppercase; }
  &__title {
    width: 50%;
    padding: 0;
    color: $white;
    font-size: 1.75em;
    font-weight: 500; }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%; }
  &__link {
    padding-left: 10px;
    color: $white;
    font-size: .95em;
    font-weight: 400;
    transition: all ease-out .3s;
    &:hover {
      color: $blue-lite; }
    &--active,
    &--row {
      color: $blue-lite;
      &:hover {
        color: $blue-lite; } } }
  &__text {
    padding-left: 0;
    color: $dark;
    font-size: .95em;
    font-weight: 400;
    line-height: 1.75;
    a {
      padding-left: 0;
      color: $blue;
      &:hover {
        opacity: 0.85;
        color: $dark; } } }
  small {
    font-style: italic;
    a {
      padding-left: 0;
      color: $dark;
      &:hover {
        opacity: 0.85;
        color: $dark; } } } }
