// Menu

.hb-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 75%;
  height: 40px;
  .btn {
    &--premium {
      @include v-sm {
        width: 88%;
        height: 60px;
        margin-top: 20px;
        margin-left: 6%;
        border-radius: 8px;
        font-size: 1.25em; } } }
  @include v-sm {
    position: fixed;
    display: block;
    top: 80px;
    left: 120vw;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: $dark;
    transition: all ease-out .4s;
    &.mob-menu {
      left: 0; } }
  &__link {
    display: inline-flex;
    align-items: center;
    height: 40px;
    margin-left: 48px;
    color: $dark;
    font-size: 1.05em;
    @include v-sm {
      display: block;
      width: 100%;
      height: 60px;
      margin-left: 0;
      padding: 0 24px;
      color: $premium;
      font-size: 1em;
      line-height: 58px;
      align-items: center;
      border-top: 1px solid darken($dark,5%); }
    &:hover,
    &.active {
      color: $premium; } } }

#top_block_page[active="about"] .hb-menu__link[current="about"],
#top_block_page[active="product"] .hb-menu__link[current="product"],
#top_block_page[active="reviews"] .hb-menu__link[current="reviews"],
.hb-menu__link.scroll.active {
  color: $premium;
  cursor: default; }
