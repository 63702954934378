.about {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: space-between;
  line-height: 1.5;
  &__title {
    margin: -4px 0;
    padding: 0;
    font-size: 2em;
    font-weight: 800;
    &--sub {
      color: $premium;
      font-size: 1em;
      font-weight: 500; } }
  &__subtitle {
    margin-bottom: 32px;
    padding: 0 4px;
    color: $dark;
    font-size: 1.3em;
    font-weight: bold;
    font-style: italic;
    font-family: $play;
    letter-spacing: .05em;
    &.right {
      text-align: right; } }
  &__text {
    margin-right: 15%;
    @include v-sm {
      width: 80%;
      padding: 5px 0;
      margin-left: auto;
      margin-right: auto; } }
  &__screen {
    @include v-sm {
      margin-left: 7%; }
    @include v-xs {
      width: 90%;
      margin-left: 10%; } }
  &__block {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 30%;
    margin-left: 15%;
    margin-bottom: 16px;
    @include v-sm {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 25px;
      width: 100%;
      text-align: center;
      clear: both; } }
  &__list {
    width: 23%;
    @include v-sm {
      width: 45%; }
    &--problems,
    &--solutions {
      margin: 0;
      padding: 0;
      li {
        display: block;
        list-style: none;
        margin: 0 0 24px;
        padding: 10px 16px 10px 16px;
        border-radius: 16px 0 0 16px;
        box-shadow: $shadow-10;
        background-color: $white;
        transition: all ease-out .3s;
        &:hover {
          color: darken($lite,15%);
          cursor: pointer;
          box-shadow: none; }
        &.active {
          border-radius: 16px 0 16px 16px;
          background-color: $blue-lite;
          &:hover {
            color: $dark;
            cursor: default;
            box-shadow: $shadow-10; } } } }
    &--solutions {
      li {
        padding: 10px 16px 8px 16px;
        border-radius: 0 16px 16px 0;
        box-shadow: shadow;
        &:hover {
          color: $dark;
          cursor: default;
          box-shadow: $shadow-10; }
        &.active {
          color: $white;
          border-radius: 16px 16px 16px 0;
          background-color: $premium;
          &:hover {
            color: $white; } } } } }
  &__dots {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    width: 8%;
    background: url(../../assets/img/line.svg) center top 45px no-repeat;
    @include v-xs {
      background: url(../../assets/img/line.svg) center top 60px repeat-y; } }
  &__dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $white;
    border: 4px solid $lite-blue;
    &.active {
      border-color: $premium; }
    &--first {
      transform: translateY(5vh); }
    &--second {
      transform: translateY(2vh); }
    &--third {
      transform: translateY(-4vh); }
    &--fourth {
      transform: translateY(-9vh); } } }
